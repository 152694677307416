<template>
	<h1>Artists</h1>
	<header>
		<div class="alpha-buttons">
			<template v-for="letter in alpha" :key="letter">
				<div @click="getArtistsByLetter($event)" :class="{ letter: true, active: startLetter == letter }">{{ letter }}</div>
			</template>
			<h3>{{ hitsCount }} Hits</h3>
		</div>
		<!-- Searchbox -->
		 <form>
			<input v-model="searchQuery" @keyup="debouncedSearch" placeholder="Search artist" />
			<div class="resetBtn">
				<span @click="resetQuery" class="material-icons resetIcon">cancel</span>
			</div>
		</form>
	</header>
	<div class="artistsContainer" v-if="searchHits.length">
		<div v-for="artist in searchHits" :key="artist.id" class="artistBox">
			<div class="artistHeader">
				<h2 v-if="artist.alias">{{ artist.alias }}</h2>
			</div>
			<router-link :to="{ name: 'Artist', params: { id: artist.id } }">
				<div class="profileImage">
					<img :src="require('../assets/profiles/' + artist.id + '.jpg')" 
					:alt="artist.name" loading="lazy"
					class="bilde"
					>
				</div>
			</router-link>
			<h3 v-if="artist.alias">
				<span id="timespan" v-if="artist.birth">{{ timestampToYear(artist.birth) }}</span>
				<span v-if="artist.death"> - {{ timestampToYear(artist.death) }}</span>
			</h3>		
		</div>
	</div>
	<footer>
		<div class="page-buttons" v-if="pages.includes(1)">
			<!-- <h2>Footer</h2> -->
			<template v-for="page in pages" :key="page">
				<div @click="getArtistsByPage($event)" :class="{ page: true, active: pageNumber == page }">{{ page }}</div>
			</template>
		</div>
	</footer>
</template>

<script>
// Firebase import
import { db } from '@/firebase/config';
import { collection, doc, getCountFromServer, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { inject, onMounted, ref, watch } from 'vue';
import { timestampToLongdate, timestampToTotalYears, timestampToYear } from '@/composables/timestampConverter';
import { toTitleCase, getNextString } from '@/composables/stringConverter';

export default {
	props: ['id', 'user'],
	emits: ['update-user','store-data'],
	setup(props, context){
		// Injects
		const artists = inject('artistConfig', 'artistConfig')

		const pages = ref([0]) // setupFooter

		//Alpha Nav
		const alpha = ref(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'])
		const startLetter = ref('A')
		const stopLetter = ref('B')
		
		const searchHits = ref([])
		const searchQuery = ref('');
		const hitsCount = ref(0)

		// Debounce
		const debounce = (func, delay) => {
			console.log('debounce!!', func, delay)
			let timeout
			return (...args) => {
				if (timeout) {
					clearTimeout(timeout)
				}
				timeout = setTimeout(() => {
					func(...args)
				}, delay)
			}
		}

		const waitForData = watch(artists, async (data) => {
			// console.log('%c data', 'color:rgb(201, 129, 226);font-size:1.2em', data)
			searchHits.value = data.filter((item) => {
				return item.name.startsWith(startLetter.value)
			})
			hitsCount.value = searchHits.value.length
		})

		// const waitForData = watch(() => artists, (data) => {
        //     console.log('data', data)
		// 	// window.addEventListener("scroll", handleScroll);
		// 	// fetchPlaylistData()
		// 	getArtists()
		// 	console.log('Fetch Artists!')
        // })


		const getArtistsByLetter = (evt) => {
			searchHits.value = []
			const clickLetter = evt.target.textContent
			startLetter.value = clickLetter
			console.log('%c startLetter.value', 'color:rgb(238, 196, 247);font-size:1.2em', startLetter.value)
			searchHits.value = artists.value.filter((item) => {
				return item.name.startsWith(startLetter.value)
			})
			hitsCount.value = searchHits.value.length
			// const index = alpha.value.indexOf(clickLetter)
			// const nextLetter = alpha.value[index+1]
			// getArtists()
		}
		const searchArtists = async () => {
			searchHits.value = []
			startLetter.value = 'abc'
			const normalizedQuery = toTitleCase(searchQuery.value)
			const nextString = getNextString(normalizedQuery)
			console.log('%c normalizedQuery', 'color:rgb(205, 66, 0);font-size:1.2em', normalizedQuery)
			console.log('%c nextString', 'color:rgb(62, 82, 173);font-size:1.2em', nextString)
			searchHits.value = artists.value.filter((item) => {
					return item.name.startsWith(normalizedQuery)
				})
				hitsCount.value = searchHits.value.length
		}

		const resetQuery = () => {
			searchHits.value = []
			searchQuery.value = ''
			startLetter.value = 'A'
			searchHits.value = artists.value.filter((item) => {
				return item.name.startsWith(startLetter.value)
			})
			hitsCount.value = searchHits.value.length
		}

		// Bruk debounce med en forsinkelse på 300ms
		const debouncedSearch = debounce(searchArtists, 400)

		const getArtists = () => {
			searchHits.value = artists.value.filter((item) => {
					return item.name.startsWith(startLetter.value)
				})
				hitsCount.value = searchHits.value.length
		}
 
		onMounted(async () => {
			console.log('%c artists.value', 'color:rgb(37, 80, 40);font-size:1.2em', artists.value.length)
			if(artists.value.length != undefined){
				getArtists()
			}
		})

		return { alpha, pages, startLetter, stopLetter, searchArtists, searchHits, searchQuery, debouncedSearch, hitsCount, getArtistsByLetter, getArtists, resetQuery,
			timestampToLongdate, timestampToTotalYears, timestampToYear, toTitleCase
		 }
	}
}
</script>

<style scoped>
form {
	position: relative;
	width: 80vw;
	display: block;
	margin: 20px auto;
}
input{
	position: relative;
	width: 80vw;
	height: 40px;
	padding: 5px;
	font-size: 1.2em;
	font-weight: 500;
}
.resetBtn {
	position: absolute;
	top: 0;
	right: 5px;
	border: none; 
	background-color: transparent; 
	cursor: pointer;
}
.resetIcon {
	color: #9c9c9c01;
}

footer {
	background: #58585845;
}
.artistsContainer{
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 10px;
}
.artistBox {
	background: #58585845;
	padding: 10px;
	border-radius: 10px;
	margin: 20px auto;
	max-width: 400px;
	min-width: 180px;
	flex: 0 1 12%;
	flex-grow: 1;
}
.artistHeader h2{
	margin: 0 auto 20px;
	text-align: center;
}
.artistBox h3{
	margin: 10px auto 5px;
	font-size: 1.2rem;
	text-align: center;
}
.profileImage img{
	width: 100%;
	display: block;
	border-radius: 50%;
	--crop-focus-x: 0.6;
	--crop-focus-y: 0.1;
	aspect-ratio: 1 / 1;
	object-fit: cover;
	object-position: calc(var(--crop-focus-x) * 100%) calc(var(--crop-focus-y) * 100%);
	margin: 0 auto;
}
.alpha-buttons,
.page-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content:center;
	align-content: space-between;
	gap: 5px;
	/* cursor: pointer; */
}
.letter,
.page {
	font-size: 1em;
	line-height: 1em;
	background: #cccccccc;
	color: #181818;
	display: block;
	padding: 5px;
	height: 30px;
	width: 30px;
	text-align: center;
	transition: all 0.5s;
}
.letter:hover,
.page:hover {
	font-weight: 700;
	background: #ddd;
	cursor: pointer;
}
.letter.active,
.page.active {
	font-size: 1.2em;
	padding-top: 4px;
	font-weight: 900;
	background: #d8afaf;
}
.controls{
	width: fit-content;
	margin: 0 auto;
}
.custom-select {
	max-width: 350px;
	margin: 0 auto;
	position: relative;
}
.custom-select select {
	appearance: none;
	width: 100%;
	font-size: 1.15rem;
	padding: 0.675em 6em 0.675em 1em;
	cursor: pointer;
	background-color: var(--dropdown-background);
	border-radius: var(--dropdown-border-radius);
	color: var(--dropdown-text-color);
}
.custom-select::before,
.custom-select::after {
	--size: 0.3rem;
	position: absolute;
	content: "";
	right: 1rem;
	pointer-events: none;
}
.custom-select::before {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-bottom: var(--size) solid black;
	top: 40%;
}
.custom-select::after {
	border-left: var(--size) solid transparent;
	border-right: var(--size) solid transparent;
	border-top: var(--size) solid black;
	top: 55%;
}
.custom-select select:hover {
	background-color: var(--dropdown-hover-color);
}

.custom-select::before {
/* ... */
	border-bottom: var(--size) solid var(--dropdown-icon-color)
}

.custom-select::after {
/* ... */
	border-top: var(--size) solid var(--dropdown-icon-color);
}
:root {
	/* light theme */
	--dropdown-background: #fff;
	--dropdown-text-color: #111;
	--dropdown-hover-color: #eee;
	--dropdown-border-color: #181818;
	--dropdown-border-radius: 0.25em;
	--dropdown-icon-color: #111;
	--background-color: #eee;
	--text-color: #111;
}
/* dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--dropdown-background: #111;
		--dropdown-text-color: #fff;
		--dropdown-hover-color: #222;
		--dropdown-border-color: #555;
		--dropdown-border-radius: 0.25em;
		--dropdown-icon-color: #fff;
		--background-color: #111;
		--text-color: #fff;
	}
}
</style>