<template>
	<div class="moduleContent" v-if="user">
		<h2>{{ user.name }} Collection</h2>
		<h3 @click="showFilter = !showFilter" class="link">
			<span v-if="showFilter">Hide filter</span>
			<span v-else>Show filter</span>
		</h3>
		<h3 @click="showStats = !showStats" class="link">
			<span v-if="showStats">Hide stats</span>
			<span v-else>Show stats</span>
		</h3>
		<div class="filter" v-if="showFilter">
			<form @change.prevent="filterCollection">
				<label for="store">Store</label>
				<select id="store" v-model="storeFilter">
					<option value="All" selected>All stores</option>
					<option v-for="(stat, index) in stats" :key="index" :value="stat.store">{{ stat.store }}</option>
				</select>
				<label for="year">Year</label>
				<select id="year" v-model="yearFilter">
					<option value="All">All</option>
					<option v-for="(year, index) in collectYears" :key="index" :value="year">{{ year }}</option>
				</select>
			</form>
			<h3>Hits: {{ filterData.length }}</h3>
			<h3 v-if="filteredAmount != 0">Total Amount: {{ filteredAmount.toLocaleString('no-NO') }}</h3>
			<h3 v-else>Total Amount: {{ totalAmount.toLocaleString('no-NO') }}</h3>
		</div>

		<div class="stats" v-if="showStats">
			<h3>Total Amount: {{ totalAmount.toLocaleString('no-NO') }}</h3>
			
			<template v-for="stat in stats" :key="stat.store">
				<div>
					<h3>{{ stat.store }}</h3>
					<p v-if="stat.totalSum">Amount: {{ stat.totalSum.toLocaleString('no-NO') }}</p>
					<p v-else>Amount: {{ totalAmount.toLocaleString('no-NO') }}</p>
					<p>Records: {{ stat.count }}</p>
				</div>
			</template>
		</div>
		<div class="moduleHits" v-if="filterData">
			<template v-for="album in filterData" :key="album.id">
				<div class="moduleItem">
					<div class="albumCover">
						<router-link :to="{ name: 'Album', class: 'albumLink', params: { id: album.id } }">
							<img :src="require('@/assets/covers/' + album.id + '.jpg')" :alt="album.id">
						</router-link>
					</div>
					<!-- <p>ID: {{ album.albumId }}</p> -->
					<p><strong>Store:</strong> {{ album.Butikk }}</p>
					<p><strong>Amount:</strong> {{ album.Sum }}</p>
					<p><strong>Ordered:</strong></p>
					<p>{{ timestampToLongdate(album.Ordered) }}</p>
					<p><strong>Collected:</strong></p>
					<p>{{ timestampToLongdate(album.Collected) }}</p>
				</div>
			</template>
		</div>
		
		<div class="total">

		</div>
	</div>
</template>

<script>
import { timestampToLongdate, timestampToYear } from '@/composables/timestampConverter';
import { auth, db } from '@/firebase/config';
import { collection, doc, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { stats } from 'instantsearch.js/cjs/widgets';
import { onMounted, ref } from 'vue';
export default {
	props: ['user'],
	setup(props) {
		const showFilter = ref(true)
		const showStats = ref(false)
		

		const collectionData = ref([])
		const filterData = ref([])
		const filteredAmount = ref(0)

		const limiter = ref(1000)
		const fromDate = ref()
		const toDate = ref()
		const store = ref()
		const storeFilter = ref('All')
		const yearFilter = ref('All')
		
		const totalAmount = ref(0)
		const stats = ref()
		const collectYears = ref([])

		const getUserCollection = async () => {
			const collectionRef = collection(db, 'users', props.user.id, 'collection')
			const collectionQuery = query(collectionRef, 
				orderBy('Collected', 'desc'), 
				// orderBy('Ordered', 'desc'),
				limit(limiter.value))
			const docSnap = await getDocs(collectionQuery)
			let tempArray = []
			docSnap.forEach((item) => {
				const data = {...item.data(), id: item.id }
				collectionData.value.push(data)
				filterData.value.push(data)
				totalAmount.value += Number(item.data().Sum)
				console.log('totalAmount: ', totalAmount.value, data)
				
				let year = timestampToYear(item.data().Collected)
				console.log('%c year', 'color:rgb(126, 12, 199);font-size:1.2em', year)
				if(!tempArray.includes(year)) {
					tempArray.push(year)
				}
				collectYears.value = tempArray
				console.log('%c collectYears.value', 'color:rgb(172, 101, 191);font-size:1.2em', collectYears.value)

			})
			console.log('%c array', 'color:rgb(71, 119, 58);font-size:1.8em', collectionData.value)
			const filter = collectionData.value.filter(s => s.Butikk === "iMusic");
			console.log('%c filter', 'color:rgb(28, 213, 60);font-size:1.2em', filter.length)
			// if (docSnap.exists()) {
			// 	console.log('docSnap.data(): ', docSnap.data())
			// 	const data = {...docSnap.data(), id: docSnap.id }
			// 	console.log('%c data', 'color:rgb(29, 127, 7);font-size:1.2em', data)
			// }
			stats.value = getSortedStores()
			console.log('%c calculateStores', 'color:rgb(140, 98, 48);font-size:2.2em', stats.value)
		}
		const filterCollection = (evt) => {
			if(evt.target.id == 'store') {
				if(storeFilter.value === 'All') {
					if(yearFilter.value === 'All') {
						filterData.value = collectionData.value // RESET
					} else {
						const filterYear = collectionData.value.filter(i => timestampToYear(i.Collected) === yearFilter.value)
						filterData.value = filterYear
					}
				} else {
					if(yearFilter.value === 'All') {
						const filterStore = collectionData.value.filter(s => s.Butikk === storeFilter.value);
						filterData.value = filterStore
					} else {
						const filterStore = collectionData.value.filter(s => s.Butikk === storeFilter.value);
						const filterYear = filterStore.filter(i => timestampToYear(i.Collected) === yearFilter.value)
						filterData.value = filterYear
					}
				}
			}
			else if(evt.target.id === 'year') {
				if(yearFilter.value === 'All') {
					if(storeFilter.value === 'All'){
						filterData.value = collectionData.value // RESET
					} else {
						const filterStore = collectionData.value.filter(s => s.Butikk === storeFilter.value);
						filterData.value = filterStore
					}
				} 
				else {
					if(storeFilter.value === 'All') {
						const filterYear = collectionData.value.filter(i => timestampToYear(i.Collected) === yearFilter.value)
						filterData.value = filterYear
					} else {
						const filterStore = collectionData.value.filter(s => s.Butikk === storeFilter.value);
						const filterYear = filterStore.filter(i => timestampToYear(i.Collected) === yearFilter.value)
						filterData.value = filterYear
					}
				}
			}

			let total = 0
			filterData.value.forEach((item) => {
				total += item.Sum
			})
			filteredAmount.value = total
			console.log('%c filteredAmount', 'color:rgb(10, 227, 228);font-size:1.2em', filteredAmount)
				// console.log('%c calcTotal', 'color:rgb(13, 102, 54);font-size:1.2em', calcTotal)

				


		}

		function getSortedStores() {
			const storeStats = {};
			// Telle antall kjøp per butikk
			collectionData.value.forEach(item => {
				const store = item.Butikk;
				if (store) {
					if (!storeStats[store]) {
						storeStats[store] = { count: 0, totalSum: 0 };
					}
					storeStats[store].count += 1; // Øker antall kjøp
					storeStats[store].totalSum += item.Sum || 0; // Legger til Sum
				}
			});

			// Konverter til array og sorter etter antall kjøp
			const sortedStores = Object.entries(storeStats)
			.map(([store, stats]) => ({
				store,
				count: stats.count,
				totalSum: stats.totalSum
			}))
			.sort((a, b) => b.count - a.count); // Sorter etter antall kjøp

			return sortedStores;
		}



		onMounted(() => {
			getUserCollection()
		})
	return { getUserCollection, filterCollection, getSortedStores, timestampToLongdate, timestampToYear, 
		collectYears, collectionData, filterData, limiter, 
		fromDate, toDate, store, showFilter, showStats, totalAmount, stats, storeFilter, yearFilter, filteredAmount,
	 }
	}

}
</script>

<style scoped>
	.moduleContent{
		background: #6d5cc120;
	}
	.moduleHits {
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(auto-fit, clamp(150px, 180px, 500px));
		justify-content: space-between;
	}
	.moduleItem{
		background: rgba(2,0,36,0.3);
		background: linear-gradient(195deg, rgba(2,0,36,0.25) 0%, rgba(9,9,121,0.25) 35%, rgba(25, 104, 177, 0.25) 100%);		
		padding: 5px;
	}
	.albumCover {
		/* align-content: center; */
		margin: auto;
		display: block;
		width: 200px;
	}
	.albumCover img{
		width: 170px;
	}
	.filter {
		background: linear-gradient(170deg, rgba(2,0,36,0.30) 0%, rgba(9,9,121,0.25) 55%, rgba(25, 104, 177, 0.25) 100%);	
		padding: 10px;
		margin: 10px 0;
	}
	.filter label {
		font-size: 1.4em;
	}
	.filter form select {
		height: 40px;
	}
	.stats {
		background: #6d5cc120;
		padding: 10px;
		margin: 10px;
	}
	h3 {
		cursor: pointer;
	}
</style>