import { ref } from "vue";

// Firebase imports
import { auth, db } from "../firebase/config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

// refs
export const authUser = ref(auth.currentUser)
export const user = ref({})


// auth cahnges
onAuthStateChanged(auth, (_authUser) =>{
    console.log('Current authUser (from getUser.js) ', _authUser)
    authUser.value = _authUser
    if(_authUser){
        getUserDoc(authUser.value.uid)
        
    }
})
// Get User Data
const getUserDoc = async (uid) => {
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
		let data =  {...docSnap.data(), id: docSnap.id }
		if(data.password){delete data.password}
        user.value = data
		console.log("User data:", {...user.value})
    } else {console.log("No document found!")}
}





// export const getUser = async () => {

//     console.log(user.value.id);
//   await new Promise(r => setTimeout(r, 300));

//   if(!user.value.id) {
//     console.warn('User is not yet defined')
//     await getUser();
//   }
//     return { authUser, user }
// }

