<template>
	<UserCollection
		:user="user"
	/>
	<UserProfile 
		:user="user" 
	/>
	<!-- <h1>Userdata</h1>
	<p>Number logins: {{ user.login.length }}</p>
	<p>Admin: {{ user.admin }}</p>
	<p>Email: {{ user.email }}</p>
	<p>Name: {{ user.name }}</p>
	<p>User ID: {{ user.id }}</p>
	<p>Created: {{ user.created }}</p>
	<p>Updated: {{ user.updated }}</p>
	<h2>Reviews: {{ user.reviews.length }}</h2>
	<template v-for="review in user.reviews" :key="review">
		<p>{{ review }}</p>
	</template>
	<h2>Favorites: {{ user.favorites.length }}</h2>
	<template v-for="favorite in user.favorites" :key="favorite">
		<p>{{ favorite }}</p>
	</template>
	<h2>Collection: {{ user.collected.length }}</h2>
	<template v-for="collect in user.collected" :key="collect">
		<p>{{ collect }}</p>
	</template> -->




</template>

<script>
import { onMounted, ref, toRaw } from 'vue';
import UserCollection from '@/components/userModules/UserCollection'
import UserProfile from '@/components/userModules/UserProfile'
import { user } from '@/composables/getUser';
export default {
	props: ['user'],
	emits: ['update-user','store-data'],
	components: { UserCollection, UserProfile },
	setup(props) {
		console.log('%c props.user', 'color:rgb(167, 224, 128);font-size:1.2em', props.user.login.length)

		onMounted(() => {
			
		})

		return {  }
	}
}
</script>