<template>
	<div class="moduleContent">
		<div class="moduleContainer">
			<template v-for="album in artistAlbums" :key="album.id">
				<!-- <div :class="{ box: true, activeAlbum: album.active}"> -->
				<div class="box">
					<h3>{{ album.Artist }}</h3>
					<router-link :to="{ name: 'Album', class: 'albumLink', params: { id: album.id } }">
						<img
						:src="require('@/assets/covers/' + album.id + '.jpg')" 
						:alt="album.Album" loading="lazy"
						:class="{ bilde: true, activeAlbum: activeAlbum.id == album.id }"
						@click="clickImage(album)">
						<!-- <p>Recorded: {{ timestampToYear(album.Recorded1) }}</p> -->
						<!-- <p>Release: {{ album.Year }}</p> -->
					</router-link>
					<span :class="{ icon: true, collectIcon: true, iconCollect: user.collected.includes(album.id)}">
						<span class="material-icons">album</span>
					</span>
					<h3>{{ album.Album }} ({{ album.Year }})</h3>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { timestampToYear } from '@/composables/timestampConverter';
import { useRouter } from 'vue-router';

export default {
	emits: ['new-album'],
	props: ['activeAlbum', 'artistAlbums', 'user'],
	setup(props, context) {
		const router = useRouter()
		// const toggleModal = () => {
		// 	context.emit('close')
		// 	console.log(props.review)
		// }

		const clickImage = (album) => {
			context.emit('new-album', album)
			// console.log(props.review)
			console.log('album: ', album)	
			// let route = `/album/${album.id}`
			// router.push(route)
			// router.go(`/album/${album.id}`)
			
		}

 	return { router, clickImage, timestampToYear }
	}

}
</script>

<style scoped>
	.box img{
		width: 100%;
		/* box-shadow: 1px 1px 3px rgba(145, 144, 144, 0.5); */
	}
	.moduleContainer{
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		flex-direction: row;
		justify-content: space-around;
	}
	.box{	
		background: transparent;
		/* margin: 10px; */
		max-width: 300px;
		min-width: 150px;
		/* min-height: 200px; */
		flex: 1 0 32%;
		flex-grow: 4;
		/* padding: 10px; */
		position: relative;
	}
	.box h3 {
		font-size: 1em;
		/* margin: 0 0; */
	}
	.box h3:last-child{
		font-size: 0.9em;
		margin-top: -5px;
	}
	.activeAlbum {
		/* border: 5px solid #dcb; */
		box-shadow: 0 0 10px blanchedalmond;
	}
</style>