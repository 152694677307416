<template>
	<div class="albumContainer" v-if="album">
		<div class="panelCover">
			<AlbumCover
				v-if="showAlbumCover"
				class="albumCover"
				:album="album"
			/>
		</div>
		<div class="panel info">
			<h3 @click="showAlbumInfo = !showAlbumInfo" class="subheading" v-if="album.Artist">{{ album.Artist }}<br>
			{{ album.Album }} ({{ album.Year }})</h3>
			
			<AlbumInfo
				v-if="showAlbumInfo"
				@new-review ="(data, user) => newReview(data, user)"
				@update-review ="(data, user) => updateReview(data, user)"
				@delete-review ="(data, user) => deleteReview(data, user)"

				@new-collected ="(data, user) => newCollected(data, user)"
				@update-collected ="(data, user) => updateCollected(data, user)"
				@delete-collected ="(data, user) => deleteCollected(data, user)"

				:showAlbumPlayer="showAlbumPlayer"
				@player="showAlbumPlayer = !showAlbumPlayer"

				:album="album"
				:userCollected="userCollected"
				:userReview="userReview"
				:user="user"
				/>
		</div>
		<div class="panel tracks">
			<h3 @click="showAlbumTracks = !showAlbumTracks" class="subheading">Tracks</h3>
			<AlbumTracks
				v-if="showAlbumTracks"
				class="albumTracks"
				:album="album"
			/>
		</div>
		<div class="panel links">
			<h3 @click="showAlbumLinks = !showAlbumLinks" class="subheading">Links</h3>
			<AlbumLinks
				v-if="showAlbumLinks"
				class="albumLinks"
				:album="album"
			/>
		</div>
		<div class="panel collection" v-if="userCollected != null">
			<h3 @click="showAlbumCollection = !showAlbumCollection" class="subheading">Collection</h3>
			<AlbumCollection
				v-if="showAlbumCollection"
				class="albumCollected"
				:album="album"
				:userCollected="userCollected"
			/>
		</div>
		<div class="panel musiciantext">
			<h3 @click="showAlbumMusicians = !showAlbumMusicians" class="subheading">Musicians</h3>
			<AlbumMusicians
				v-if="showAlbumMusicians"
				:album="album"
			/>
		</div>
		<div class="panel player" v-if="album.Apple">
			<!-- <h3 @click="showAlbumPlayer = !showAlbumPlayer" class="subheading">Player</h3> -->
			<AlbumPlayer
				v-if="showAlbumPlayer"
				class="player"
				:album="album"
			/>
		</div>
		<div class="panel reviews">
			<h3 @click="showAlbumReviews = !showAlbumReviews" class="subheading">Reviews</h3>
			<AlbumReviews
				v-if="showAlbumReviews"
				:album="album"
				:reviews="reviews"
				:user="user"
			/>
		</div>
		<div class="panel musicians">
			<h3 @click="showAlbumArtists = !showAlbumArtists" class="subheading">Album Artist</h3>
			<AlbumArtists
				v-if="showAlbumArtists"
				class="albumArtists"
				:album="album"
				:artistNames="artistNames"
				:artistIDs="artistIDs"
				:artistAlias="artistAlias"
				:musicians="musicians"
			/>
		</div>
		<div v-if="artistAlbums.length != 0" class="panel albums">
			<h3 @click="showArtistAlbums = !showArtistAlbums" class="subheading">{{ album.Artist }} has {{ artistAlbums.length }} albums</h3>
			<ArtistAlbums
				v-if="showArtistAlbums"
				class="albumArtists"
				:artistAlbums="artistAlbums"
				:activeAlbum="album"
				:user="user"
				@new-album="emitAlbum"
			/>	
		</div>
		<div  v-if="musicianAlbums.length != 0" class="panel artistmusican">
			<h3 @click="showMusicianAlbums = !showMusicianAlbums" class="subheading">{{ album.Artist }} is playing on {{ musicianAlbums.length }} albums</h3>
			<ArtistMusician
				v-if="showMusicianAlbums"
				class="albumArtists"
				:artistAlbums="musicianAlbums"
				:activeAlbum="album"
				:user="user"
				@new-album="emitAlbum"
			/>
		</div>
	</div>
</template>

<script>
// IMPORTS
import { ref } from 'vue'
import StarRating from 'vue-star-rating'
// MODALS
import EditReview from '@/components/modals/EditReview.vue'
// MODULES: albumModules
import AlbumArtists from '@/components/albumModules/AlbumArtists'
import AlbumCollection from '@/components/albumModules/AlbumCollection'
import AlbumCover from '@/components/albumModules/AlbumCover'
// import AlbumDates from '@/components/albumModules/AlbumDates'
import AlbumInfo from '@/components/albumModules/AlbumInfo'
import AlbumLinks from '@/components/albumModules/AlbumLinks'
import AlbumMusicians from '@/components/albumModules/AlbumMusicians'
import AlbumPlayer from '@/components/albumModules/AlbumPlayer'
import AlbumReviews from '@/components/albumModules/AlbumReviews'
import AlbumTracks from '@/components/albumModules/AlbumTracks'
import ArtistAlbums from '@/components/albumModules/ArtistAlbums'
import ArtistMusician from '@/components/albumModules/ArtistMusician'

export default {
	components: { EditReview, AlbumInfo, AlbumCover, AlbumArtists, AlbumMusicians, AlbumTracks, AlbumLinks, AlbumReviews, AlbumCollection, StarRating, ArtistAlbums, ArtistMusician, AlbumPlayer },
	emits: ['new-album','new-review', 'update-review', 'delete-review', 'new-collected', 'update-collected', 'delete-collected'],
	props: ['user','album','reviews','artistNames','artistAlias','artistIDs','artistAlbums','musicianAlbums', 'userCollected', 'musicians', 'userReview'],
	setup(props, context) {
		
		// Show/Hide Panels
		const showAlbumCover = ref(true)
		const showAlbumInfo = ref(true)
		const showAlbumDates = ref(true)
		const showAlbumTracks = ref(true)
		const showAlbumLinks = ref(true)
		const showAlbumReviews = ref(true)
		const showAlbumCollection = ref(true)
		const showAlbumMusicians = ref(true)
		const showAlbumArtists = ref(true)
		const showArtistAlbums = ref(true)
		const showMusicianAlbums = ref(true)
		const showAlbumPlayer = ref(false)

		const newReview = (data, user) => {
			console.log('albumModule newReview: ', data, user)
			context.emit('new-review', data, user)
		}
		const newCollected = (data, user) => {
			console.log('AlbumModule newCollected: ', data, user)
			context.emit('new-collected', data, user)
		}
		const updateCollected = (data, user) => {
			context.emit('update-collected', data, user)
		}
		const deleteCollected = (data, user) => {
			context.emit('delete-collected', data, user)
		}
		const updateReview = (data, user) => {
			context.emit('update-review', data, user)
		}
		const deleteReview = (data, user) => {
			console.log('deleteReview: AlbumModule', data, user)
			context.emit('delete-review', data, user)
		}
		const emitAlbum = (album) => {
			console.log('emit album: ', album)
			context.emit('new-album', album)
		}

		return { showAlbumCover, showAlbumInfo, showAlbumDates, showAlbumArtists, showAlbumMusicians, 
			showAlbumTracks, showAlbumLinks, showAlbumReviews, showArtistAlbums, showMusicianAlbums,
			emitAlbum, showAlbumCollection, showAlbumPlayer, newReview, updateReview, deleteReview, newCollected, updateCollected, deleteCollected
		 }
	}

}
</script>

<style>
.moduleContent{
	font-size: 1em;
	font-weight: 200;
	padding: 0 10px 10px;
	/* background: rgba(159, 83, 35, 0.208); */
	line-break: strict;
}
/* moduleContent Inline Styling */
.moduleContent .strong{
	font-weight: 600;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.moduleContent p {
	margin-bottom: 10px;
}
.moduleContent .metadata {
	margin: 10px 0 0;
	line-height: 0.6em;
	font-size: 0.9em;
	font-weight: 200;
	font-style: italic;
}
.moduleContent .metadata .metastrong{
	font-weight: 600;
}
/* Panels Inline Styling */
.panel .subheading {
	font-size: 1.4em;
	text-align: center;
    cursor: pointer;
}
.panel h3,
.panel h4 {
	font-size: 1.4em;
	text-align: center;
	padding: 10px 0;
	text-shadow: 2px 1px 2px rgba(0,0,0,0.3);
	/* background: #837d7d33; */
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.panel h4 {
	font-size: 1.1em;
	font-weight: 700;
}

/* GRID */
.albumContainer {
	display: grid;
	gap: 5px;
	/* grid-auto-flow: column; */
	grid-template-rows: repeat(17, 50px) repeat(3, auto);
	grid-template-columns: repeat(15, 1fr) repeat(3, 100px);
}
.panel{
	/* background: #5c37b130; */
	background: #0F2027;  /* fallback for old browsers */
	background: linear-gradient(to right, #2C536440, #203A4340, #0F202740); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background: -webkit-linear-gradient(to right, #2C536440, #203A4340, #0F202740);  /* Chrome 10-25, Safari 5.1-6 */

	overflow: hidden;
}
.panelCover img {
	width: 100%;
	/* To fix overflow on 1800px */
	max-height: 545px; 
	object-fit: cover;
}
.panelCover{
	order: 1;
	grid-column: 5 / span 5;
	grid-row: 1;
}
.panel.info{
	order: 2;
	grid-column: 1 / span 4;
	grid-row: 1 / span 10;
}
.panel.tracks {
	order: 3;
	grid-column: 10 /  span 3;
	grid-row: 1 / span 6;
}
.panel.musiciantext {
	order: 4;
	grid-column: 13 / span 3;
	grid-row: 1 / 6;
}
.panel.links {
	order: 5;
	grid-column: 13 / span 3;
	grid-row: 6 / span 5;
}
.panel.collection {
	order: 6;
	grid-column: 10 / span 3;
	grid-row: 7 / span 4;
}
.panel.player {
	order: 7;
	grid-column:  span 3 / -1;
	grid-row: span 10;
}
.panel.reviews {
	order: 8;
	grid-column: 1 / -1;
	grid-row: 11 / span 7;
}
.panel.musicians {
	order: 9;
	grid-column: 1 / -1;
	grid-row: -3 / span 1;
}
.panel.albums {
	order: 10;
	grid-column: 1 / -1;
	grid-row: -2 / span 1;
}
.panel.artistmusican {
	order: 11;
	grid-column: 1 / -1;
	grid-row: -1 / span 1;
}

@media screen and (max-width: 1520px) {
	.albumContainer {
		grid-template-columns: repeat(12, 1fr) repeat(3, 100px);
		grid-template-rows: repeat(19, 50px) repeat(4, auto);
	}
	.panelCover {
		grid-column: 5 / span 7;
	}
	.panel.player {
		grid-column: span 4 / -1;
	}
	.panel.musiciantext {
		grid-column: span 4 /  -1;
		grid-row: 11 / span 5;
	}
	.panel.collection {
		grid-column: 9 / span 3;
		grid-row: 11 / span 5;
	}
	.panel.reviews {
		grid-row: 16 / span 6;
	}
	.panel.links {
		grid-row: 11 / span 5;
		grid-column: 5 / span 4;
	}
	.panel.tracks {
		order: 3;
		grid-row: 11 / span 5;
		grid-column: 1 / span 4;
	}
	.panel.musicians {
		grid-row: -3;
	}
	.panel.albums {
		grid-row: -2;
	}
	.panel.artistmusican {
		grid-row: -1;
	}
}
@media screen and (max-width: 1220px) {
	.albumContainer {
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: repeat(18, 50px) repeat(5, auto);
	}
	.panelCover {
		grid-column: 5 / span 4;
	}
	.panel.tracks {
		grid-column: span 3;
	}
	.panel.links {
		grid-column: 4 / span 3;
	}
	.panel.musiciantext {
		grid-column: 10 / span 3;
		grid-row: 11 / span 5;
	}
	.panel.collection {
		grid-column: 7 / span 3;
		grid-row: 11 / span 5;
	}
}
@media screen and (max-width: 900px) {
	.albumContainer {
		grid-template-columns: repeat(9, 1fr);
		grid-template-rows: repeat(16, 50px) repeat(4, auto);
	}
	.panel.info {
		grid-row: span 11;
	}
	.panel.tracks {
		grid-column: 5 / -1;
		grid-row: 8 / span 4;
	}
	.panel.links {
		grid-row: 12 / span 5;
		grid-column: 1 / span 3;
	}
	.panel.collection {
		grid-row: 12 / span 5;
		grid-column: 4 / span 3;
	}
	.panel.musiciantext {
		grid-row: 12 / span 5;
		grid-column: 7 / span 3;
	}
	.panel.player {
		grid-column: 2 / -2;
		grid-row: 17 / span 1;
		width: 100%;
	}
	.panel.reviews {
		grid-row: -4 / span 1;
	}
	.panel.musicians {
		grid-row: -3;
	}
	.panel.albums {
		grid-row: -2;
	}
	.panel.artistmusican {
		grid-row: -1;
	}
}
@media screen and (max-width: 800px) {
	.albumContainer {
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(17, 50px) repeat(4, auto);
	}
	.panelCover {
		grid-column: 1 / span 3;
	}
	.panel.info {
		grid-column: 4 / span 3;
		grid-row: 1 / span 9;
	}
	.panel.tracks {
		grid-column: 1 / span 3;
		grid-row: 8 / span 5;
	}
	.panel.links {
		grid-column: 4 / -1;
		grid-row: 14 / 18;
	}
	.panel.collection {
		grid-row: 10 / span 4;
	}
	.panel.musiciantext {
		grid-column: 1 / 4;
		grid-row: 13 / 18;
	}
	.panel.reviews {
		grid-row: -4 / span 1;
	}
	.panel.player {
		grid-column: 1 / -1;
		grid-row: 18 / span 1;
	}
	.panel.musicians {
		grid-column: 1 / -1;
	}
}
@media screen and (max-width: 600px) {
	.albumContainer {
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(12, auto);
	}
	.panelCover {
		grid-row: 1;
		grid-column: 1 / span 4;
	}
	.panel.info {
		grid-row: 2;
		grid-column: 1 / span 4;
	}
	.panel.tracks {
		grid-row: 3;
		grid-column: 1 / span 4;
	}
	.panel.links {
		grid-row: 4;
		grid-column: 1 / span 4;
	}
	.panel.collection {
		grid-row: 5;
		grid-column: 1 / span 4;
	}	
	.panel.musiciantext {
		grid-row: 6;
		grid-column: 1 / span 4;
	}
	.panel.player {
		grid-row: 7;
		grid-column: 1 / span 4;
	}
	.panel.reviews {
		grid-row: 8;
		grid-column: 1 / span 4;
	}
	.panel.musicians {
		grid-row: 9;
		grid-column: 1 / span 4;
	}
	.panel.albums {
		grid-row: 10;
		grid-column: 1 / span 4;
	}
	.panel.artistmusican {
		grid-row: 11;
		grid-column: 1 / span 4;
	}
}
</style>