<template>
	<div class="moduleImage">
		<img
			:src="require('@/assets/covers/' + album.id + '.jpg')" 
			:alt="album.Album" loading="lazy"
			class="bilde"
			@click="coverSize($event)">
	</div>
</template>

<script>
export default {
	props: ['album'],
	setup(props) {
		const coverSize = (event) => {
			console.log('event: ', event)
			console.log('naturalHeight: ', event.target.naturalHeight)
			console.log('naturalWidth: ', event.target.naturalWidth)
		}


	return { coverSize }
	}

}
</script>