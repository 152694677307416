<template>
	<h1>Setup Artist</h1>
	<form @submit.prevent="updateArtist">
		<div class="formContainer">
			<div class="inpContainer">
				<div class="inp">
					<label for="artistID">artistID</label>
					<input id="artistID" type="text" placeholder="artistID" v-model="artist.artistID">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="alias">Alias</label>
					<input id="alias" type="text" placeholder="alias" v-model="artist.alias">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="name">Name</label>
					<input id="name" type="text" placeholder="name" v-model="artist.name">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="instrument">Instruments (separated by ', ')</label>
					<input id="instrument" type="text" placeholder="instruments separated by ', '" v-model="artist.instrument">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="birth">Birth</label>
					<input id="birth" type="date" placeholder="birth" v-model="artist.birthDate">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="death">Death</label>
					<input id="death" type="date" placeholder="death" v-model="artist.deathDate">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="bioAmg">Allmusic URL</label>
					<input id="bioAmg" type="text" placeholder="bioAmg" v-model="artist.bioAmg">
				</div>
			</div>
			<div class="inpContainer">
				<div class="inp">
					<label for="bioWiki">Wikipedia URL</label>
					<input id="bioWiki" type="text" placeholder="Title" v-model="artist.bioWiki">
				</div>	
			</div>
			<div class="inpContainer bioBox">
				<div class="inp">
					<label for="bioText">Biography</label>
					<textarea id="bioText" type="text" placeholder="bioText" v-model="artist.bioText"></textarea>
				</div>
			</div>
			<button @click="updateArtist">Update</button>
		</div>
	</form>
</template>

<script>
import { getArtist } from '@/composables/getDoc';
import { guardRoute } from '@/composables/guardRoute';
import { timestampToInput } from '@/composables/timestampConverter';
import { db } from '@/firebase/config';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { onMounted, ref, watchEffect } from 'vue';

export default {
	props: ['id', 'user'],
	emits: ['update-user','store-data'],
	setup(props){
		const artist = ref({})


		const updateArtist = async () => {
			console.log('%c Start Artist Update...', 'color:#9ca;font-size:1.4em',  artist)
            const data = {...artist.value}
			data.updated = serverTimestamp()
			// Update Firestore Artist
			const artistRef = doc(db, 'artist', props.id)
			const updateFirestore = await updateDoc(artistRef, data, { merge: true })
			console.log('%c Artist Updated!', 'color:SeaGreen;font-size:1.2em',  data)
			
			// HUSK!!! Lag en funksjon som oppdaterer config filen ved endringer av name / alias / artistID
			// HUSK!!! Emit endringer opp til artisten, som proppes inn her
		}

		// const updateCollection = async (userCollected) => {
		// 	console.log('updateCollection()', userCollected)
		// 	// Update userReview
		// 	let updatedData = {...userCollected }
		// 	delete updatedData.timeUpdated
		// 	delete updatedData.timeCreated
		// 	updatedData.updated = serverTimestamp()
			
		// 	// Update Firestore Review
		// 	const reviewRef = doc(db, 'users', user.value.id, 'collection', props.album.id)
		// 	const updatedCollect = await updateDoc(reviewRef, updatedData, { merge: true })

		// 	// make custom date for faking firestore timestamp
		// 	updatedData.updated = Timestamp.fromDate(new Date())

		// 	// Emit data to grandparent (Album.vue)
		// 	context.emit('update-collected', updatedData, user)
			
		// 	// Close Modal
		// 	closeModal() 
			
		// 	// Log results
		// 	console.log('%c Updated Review ','color:white;font-size:1.2em;background:orange', updatedData)
		// }


		onMounted( async () => {
			// console.log('%c props', 'color:gold;font-size:1.2em',  props.id)
			// console.log('%c user.admin', 'color:rgb(238, 111, 183);font-size:1.2em', props.user.admin)
			const guard = await guardRoute(props.user) // guard: boolean
			// console.log('%c Guard Admin', 'color:rgb(0, 104, 132);font-size:1.2em', guard)
			if(props.artist == null){
				const fetchArtist = await getArtist(props.id)
				let data = { ...fetchArtist }
				console.log('data: ', data)
				if (fetchArtist.birth !== undefined && fetchArtist.birth !== ''){
					data.birthDate = timestampToInput(fetchArtist.birth)
					data.birth = fetchArtist.birth
				}
				if (fetchArtist.death !== undefined && fetchArtist.death !== ''){
					data.deathDate = timestampToInput(fetchArtist.death)
					data.death = fetchArtist.death
				}
				// console.log('%c data', 'color:rgb(226, 41, 99);font-size:1.2em', data)
				artist.value = data
				// console.log('%c fetchArtist', 'color:gold;font-size:1.2em',  fetchArtist)
			}
		})

		return {  getArtist, artist, updateArtist, timestampToInput, guardRoute }
	}
}
</script>

<style scoped>
textarea {
	min-height: 30vh;
	width: 100%;
	overflow-y: scroll;
	resize: vertical;
}
.bioBox {
	grid-column: 1 / -1;
}
.formContainer{
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	justify-content: space-around;
}
.formContainer button {
	grid-column: 1 / -1;
}

/* COPY from EditAlbum.vue */
form{
    /* background: #f0e9e822; */
    padding: 20px;
    border-radius: 20px;
}
form label, form input, form select {
    display: block;
}
form input, form select {
	/* width: 100%; */
    padding: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
    font-size: 1em;
}
form select{
	width: 180px;
}
/* .formContainer{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
} */
.container{
	background: transparent;
}
.inpContainer{
	display: block;
	background: rgba(91, 116, 226, 0.208);
	/* margin: 20px; */
	padding: 10px;
	border-radius: 10px;
	max-width: 100%;
}
.inp input:not(:placeholder-shown) {
	font-weight: 700;
	font-size: 1.2em;
}
.inpContainer h2, 
.inpContainer h3 {
	text-align: center;
	margin-bottom: 15px;
}
.inpContainer label{
	text-align: center;
}
.head{
	display: block;
	text-align: center;
	width: fit-content;
	margin: 10px auto;
}
</style>