<template>
	<h1>All Reviews</h1>
	<header>
		<div class="alpha-buttons">
			<template v-for="letter in alpha" :key="letter">
				<div @click="getReviewsByLetter($event)" :class="{ letter: true, active: startLetter == letter }">{{ letter }}</div>
			</template>
		</div>
	</header>
	<ReviewsModule
		:reviews="reviews"
		:album="album"
		v-if="reviews"
	/>
	<div v-if="!reviews">
		<h3>No Reviews</h3>
	</div>
</template>
  
<script>
import ReviewsModule from '@/components/ReviewsModule.vue'
import { db } from '@/firebase/config'
import { formatDistanceToNow } from 'date-fns'
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { onMounted, ref } from 'vue'
  
  export default {
	components: { ReviewsModule },
	props: ['user'],
	emits: ['update-user','store-data'],
	setup(props) {
	  const reviews = ref([])
	  const album = ref(null)

	  const alpha = ref(['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'])
	  const startLetter = ref('A')
	  const stopLetter = ref('B')
	  const activeSorting = ref('albumTitle')
	  const limiter = ref(50)

	  const getReviews = async () => {
			const q1 = [activeSorting.value,'>=', startLetter.value]
			const q2 = [activeSorting.value,'<=', stopLetter.value]
			const colRef = collection(db, 'reviews')
			const queryRef = query(colRef, 
				where(...q1),
				where(...q2),
				orderBy(activeSorting.value),
				limit(limiter.value)
			)
			const querySnapshot = await getDocs(queryRef);
			querySnapshot.forEach( async (review) => {
				const albumID = review.data().albumID
				const albumSnapshot = await getDoc(doc(db, 'Jazz', albumID))
				const albumData = albumSnapshot.data()
				album.value = {id: albumSnapshot.id, ...albumSnapshot.data()}
				let timeCreated, timeUpdated
				if(review.data().created){timeCreated = formatDistanceToNow(review.data().created.toDate())}
				if(review.data().updated){timeUpdated = formatDistanceToNow(review.data().updated.toDate())}
				reviews.value.push({
					...review.data(),
					reviewID: review.id,
					id: review.id,
					timeCreated: timeCreated,
					timeUpdated: timeUpdated,
					albumData: albumData
				})
			});
			console.log('Reviews fetched', reviews.value.length , startLetter.value, reviews.value)
		}

		const getReviewsByLetter = (evt) => {
			// console.log('evt: ', evt)
			const clickLetter = evt.target.textContent
			startLetter.value = clickLetter
			// console.log('clickLetter: ', clickLetter)
			const index = alpha.value.indexOf(clickLetter)
			// console.log('index: ', index)
			const nextLetter = alpha.value[index+1]
			if(clickLetter != 'Z'){
				stopLetter.value = nextLetter
			} else {
				stopLetter.value = 'z'
			}
			// console.log('nextLetter: ', nextLetter)
			reviews.value = []
			getReviews()
		}

	  // Startup
	  onMounted(()=>{
		// fetchReviews()
		getReviews()
	  })
  
	  return { reviews, album, alpha, startLetter, stopLetter, getReviews, getReviewsByLetter, activeSorting, limiter }
	}
  }
  </script>

  <style scoped>
  	.alpha-buttons{
		/* margin: 0 auto; */
		/* background: #cac4c4; */
		display: flex;
		flex-wrap: wrap;
		justify-content:center;
		align-content: space-between;
		gap: 5px;
		cursor: pointer;
	}
	.letter{
		/* width: 50px; */
		font-size: 1em;
		line-height: 1em;
		background: #cccccccc;
		color: #181818;
		display: block;
		padding: 5px;
		height: 30px;
		width: 30px;
		text-align: center;
		transition: all 0.5s;
	}
	.letter:hover{
		font-weight: 700;
		background: #ddd;
	}
	.letter.active{
		font-size: 1.2em;
		padding-top: 4px;
		font-weight: 900;
		background: #d8afaf;
	}
  </style>