<template>
	<div class="moduleContent">
		<h1>User Profile</h1>
		<p>Number logins: {{ user.login.length }}</p>
		<p>Admin: {{ user.admin }}</p>
		<p>Email: {{ user.email }}</p>
		<p>Name: {{ user.name }}</p>
		<p>User ID: {{ user.id }}</p>
		<p>Created: {{ user.created }}</p>
		<p>Updated: {{ user.updated }}</p>
		<h2>Reviews: {{ user.reviews.length }}</h2>
		<!-- <template v-for="review in user.reviews" :key="review">
			<p>{{ review }}</p>
		</template> -->
		<h2>Favorites: {{ user.favorites.length }}</h2>
		<!-- <template v-for="favorite in user.favorites" :key="favorite">
			<p>{{ favorite }}</p>
		</template> -->
		<h2>Collection: {{ user.collected.length }}</h2>
		<!-- <template v-for="collect in user.collected" :key="collect">
			<p>{{ collect }}</p>
		</template> -->
		
	</div>
</template>

<script>
export default {
	props: ['user'],
	setup(props) {


	return {}
	}

}
</script>

<style scoped>
	.moduleContent{
		background: #6d5cc18d;
	}
</style>